import React from "react"
import loadable from '@loadable/component'

import SEO from '../components/SEO'

const Logo = loadable(() => import("../components/Logo"));
const Content = loadable(() => import('../components/Content'));
const Section = loadable(() => import('../components/Section'));
const InnerSection = loadable(() => import('../components/InnerSection'));

export default function Home() {
  return (
    <main>
      <SEO/>
      <Logo/>
      <Content>
        <Section title='Política de Privacidade E Termo de uso' id='how'>
          <InnerSection style={{margin: '70px 25px 100px 25px'}}>
            <p>
              Através do presente instrumento de PRIVACIDADE E TERMO DE USO, o USUÁRIO(A), declara ter lido e compreendido que:
            </p>
            <br/>
            <br/>
            <h2>
              Dados Pessoais do Usuário e do PET
            </h2>
            <p>
              O Usuário autoriza a URDOG a realizar a coleta, o armazenamento e o tratamento, dos seguintes dados pessoais,
              para os fins que serão relacionados neste instrumento, desde que o acesso, a recolha, a utilização e a
              partilha de dados pessoais ou confidenciais sejam limitados às finalidades relacionadas
              diretamente com o fornecimento e o melhoramento das funcionalidades do app<br />
              <ul>
                <li>Dados em relação a atividade e sono do proprietário;</li>
                <li>Dados em relação a atividade e sono do Pet;</li>
                <li>Localização do celular do proprietário todo tempo;</li>
                <li>Localização do pet;</li>
                <li>Nome completo do Usuário</li>
                <li>Data de nascimento do Usuário;</li>
                <li>Endereço completo do Usuário;</li>
                <li>Endereços de e-mail;</li>
                <li>Toda a forma de Comunicação mantida entre o USUÁRIO e a URDOG;</li>
                <li>Nome, Raça e idade do “Pet”;</li>
                <li>Movimentos e caminhadas do Pet;</li>
                <li>Carteira de vacinação do PET;</li>
                <li>Relatório e Exames e Atestados Médicos do PET de sua propriedade;</li>
                <li>Dados capturados pela coleira e através do aplicativo;</li>
              </ul>
              <br/>
                Fica claramente expresso. por meio desta. que a Urdog não fará a
                venda de qualquer dados pessoais ou confidenciais do utilizador.
                <br />
                O acesso do app só é liberado após aceitação dos termos no próprio aplicativo,
                de forma clara e inequívoca;
              <br/>
            </p>
            <br/>
            <h2>
              Dados Pessoais, Confidencias e Localização do celular.
            </h2>
            <p>
              Entre os dados confidenciais capturados encontra-se a posição do celular
              via GPS, que é capturada em segundo plano mesmo com o aplicativo fechado.
              <br />
              <br />
              Essa captura se da de forma que possamos inferir a posição do pet
              quando existe conectividade bluetooth, mas também para a comparação
              entre a posição do pet e do proprietário, de forma a providenciar contexto
              para notificações e outros demais dados capturados.
              <br />
              <br />
              Os dados da localização do proprietário são considerados confidenciais,
              são transmitidos de forma seegura usando o protocolo HTTPS e utilizados de
              forma restrita e limitada às finalidades relacionadas diretamente com o
              fornecimento e o melhoramento das funcionalidades do app, nunca sendo
              vendidos ou compartilhados com terceiros.
              <br/>
            </p>
            <br/>
            <h2>
              Informações no Aplicativo
            </h2>
            <p>
              Ao acessar o app o Usuário terá acesso aos dados, capturados do seu “pet” e de sí mesmo, em formatos informativos.<br/>
              <ul>
                <li>O Usuário terá acesso a “insights” gerados pelos sistemas URDOG e inteligência artificiais treinadas pelo sistema.</li>
                <li>O Usuário receberá “alertas” sobre parte desses dados, bem como a indicação do comportamento do seu pet.</li>
                <li>O Usuário não terá acesso a todos os dados capturados, não na sua forma originalmente capturada.</li>
                <li>O Usuário terá informações capturadas que indicam a atividade, posição e temperatura do seu “pet”.</li>
                <li>O Usuário terá a opção de escolher ativar o modo “perdi meu cachorro” caso deseje conhecer a localização aproximada do seu “pet” em tempo real mesmo a distância.</li>
                <li>O Usuário, à medida que novas versões da coleira inteligente e do aplicativo sejam lançadas, poderá ter disponibilizados e ou alterados dados e informações, apresentados ou não de forma diferente daquelas já disponíveis na versão atual.</li>
                <li>O Usuário poderá decidir fazer uso das seguintes sugestões: </li>
                <li>Como interagir melhor com o “pet”:  melhor hora pra caminhar, brincar ou dormir.</li>
                <li>Eventuais alertas sobre comportamentos inusuais, como, exemplificativamente, aumento anormal de atividades ou temperatura.</li>
              </ul><br/>
              O App não fornecerá, e, portanto, o Usuário não terá:<br/>
              <ul>
                <li>Diagnósticos veterinários do “pet”.</li>
                <li>Prognósticos de saúde do “pet”</li>
                <li>Orientação quanto à prevenção de doenças.</li>
              </ul>
            </p>

            <br/>
            <h2>
              A URDOG não é responsável:
            </h2>
            <p>
              <ul>
                <li>Quando da utilização incorreta da coleira, como, exemplificativamente, mas não exaustivamente, a exposição a água.</li>
                <li>Quando do uso incorreto de carregador, da tomada e da oferta e oscilação de energia elétrica, que possam danificar parcial ou totalmente o dispositivo eletrônico.</li>
                <li>Quando da indisponibilidade de sinal “go’s” ou “gsm” que possam dificuldade a obtenção da posição do “pet” em tempo real, assim como, dificultar a transmissão de dados obtidos e ou disponibilização de informações a partir dos Sistemas Internos da URDOG.</li>
                <li>Quanto ao recebimento de eventuais campanhas de marketing que venham a ser transmitidas por parceiros da URDOG.</li>
              </ul>
            </p>

            <br/>
            <h2>
              Finalidade do Tratamento dos Dados
            </h2>
            <p>
            O USUÁRIO autoriza que a URDOG utilize os dados que caracterizam e derivam do PET e seus dados pessoais listados, para as seguintes finalidades:<br />
            <ul>
              <li>Permitir, o que desde já autoriza, que a URDOG identifique e promova contatos com o Usuário, em razão da coleta dos dados do PET, armazenados;</li>
              <li>Para cumprimento de obrigações decorrentes da legislação aplicável. </li>
              <li>Para procedimentos de inclusão, exclusão e alteração em dados técnicos do SISTEMA que suporta o App.</li>
              <li>Para cumprimento, pela URDOG, de obrigações impostas por órgãos de fiscalização;</li>
              <li>Quando necessário na execução de contratos, no qual haja menção o USUÁRIO do App;</li>
              <li>A pedido do USUÁRIO, titular dos dados;</li>
              <li>Para o exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
              <li>Para a proteção da vida ou da incolumidade física do USUÁRIO, do seu PET ou de terceiros;</li>
              <li>Para tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária;</li>
              <li>Quando necessário para atender aos interesses legítimos da URDOG ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do USUÁRIO que exijam a proteção dos dados pessoais;</li>
              <li>Para contratação de serviços de prestação de benefícios, tais como, exemplificativamente, disponibilizar informações de interesse do USUÁRIO e de benefício do seu PET</li>
              <li>Para dispor informação sobre opção de contratação de serviços referente a prestação do fornecimento de plano de saúde e odontológico e demais relacionados ao seu PET. </li>
              <li>Permitir que a URDOG utilize esses dados para a contratação e prestação de serviços diversos dos inicialmente ajustados, desde que o Usuário também demonstre, expressamente e por escrito, o interesse em contratar novos serviços;</li>
            </ul>
            </p>

            <br/>
            <h2>
              Compartilhamento de Dados
            </h2>
            <p>
              A URDOG fica autorizada a compartilhar os dados pessoais não confidenciais do USUÁRIO e ou de seu “pet” com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste instrumento, desde que, sejam respeitados os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação.<br />
            </p>

            <br />
            <h2>
              Responsabilidade pela Segurança dos Dados
            </h2>
            <p>
              A URDOG se responsabiliza por manter medidas de segurança, técnicas e administrativas para proteger os dados pessoais do Titular, comunicando ao Titular, caso ocorra algum incidente de segurança que possa acarretar risco ou dano relevante, conforme artigo 48 da Lei n° 13.709/2020 LGPD.<br />
              O USUÁRIO(a) que tiver acesso a quaisquer dados pessoais de fornecedores e terceirizados em geral, compromete-se a cumprir a Lei De Proteção De Dados Pessoais mantendo sigilo, protegendo os dados pessoais e agindo de acordo com esta política de proteção de dados adotada pela URDOG.<br /><br />
              O USUÁRIO (a) compromete-se a manter o dever de sigilo sobre as informações obtidas no uso do SISTEMA URDOG, inclusive em relação de senhas, informações internas e relacionadas a URDOG comprometendo-se não discutir com terceiros, revelar, divulgar, comunicar através de qualquer meio de comunicação, inclusive após a sua eventual paralização da utilização do SISTEMA URDOG.<br /><br />
              A URDOG somente poderá distribuir, transmitir, exibir, reproduzir, publicar informações de dados pessoais que são controlados pela URDOG, mediante expressa autorização e ou permissão pela aceitação deste TERMO de USO.  <br /><br />
              O USUÁRIO, que será o único a ter acesso permitido ao SISTEMA URDOG, declara que cadastrou seu email (login) e criou uma senha e que ambos são pessoais e confidenciais não sendo permitido o seu empréstimo a quem quer que seja.<br /><br />
              A URDOG utiliza de uma série de boas práticas em termos de software e hardware para proteger o SISTEMA URDOG e garantir a integridade dos dados do programa. Qualquer tentativa de comprometer o SISTEMA URDOG por qualquer USUÁRIO sem ele estar devidamente autorizado para tal, ocasionara ações cabíveis.<br /><br />
              O USUÁRIO (a) declara ciência que o ambiente do SISTEMA URDOG é monitorado e que a URDOG poderá utilizar referidos dados para política de segurança de ambientes, bem como, para processos administrativos e judiciais.<br /><br />
            </p>

            <br />
            <h2>
              Término do Tratamento dos Dados
            </h2>
            <p>
            À URDOG, é permitido manter e utilizar os dados pessoais do USUÁRIO e de seu “PET” durante todo o período contratualmente firmado, para as finalidades relacionadas nesse termo, e, ainda, após o término da contratação para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei n° 13.709/2018.
            </p>

            <br />
            <h2>
              Direito de Revogação do Consentimento
            </h2>
            <p>
              O USUÁRIO poderá revogar seu consentimento, a qualquer tempo, por e-mail ou por carta escrita, conforme o artigo 8°, § 5°, da Lei n° 13.709/2020.<br />
              O USUÁRIO fica ciente de que a URDOG poderá permanecer utilizando os dados para as seguintes finalidades:<br />
              <ul>
                <li>Para cumprimento de obrigações decorrentes da legislação brasileira.</li>
                <li>Para cumprimento, pela URDOG, de obrigações impostas por órgãos de fiscalização;</li>
                <li>Para o exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
                <li>Para a proteção da vida ou da incolumidade física do titular ou de terceiros;</li>
                <li>Quando necessário para atender aos interesses legítimos da URDOG ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do USUÁRIO que exijam a proteção dos dados pessoais.</li>
              </ul>
            </p>

            <br />
            <h2>
              Tempo de Permanência dos Dados Recolhidos
            </h2>
            <p>
              As partes poderão entrar em acordo, quanto aos eventuais danos causados, caso exista o vazamento de dados pessoais ou acessos não autorizados, e caso não haja acordo, a URDOG tem ciência que estará sujeita às penalidades previstas no artigo 52 da Lei n° 13.709/2018.<br /><br />
              Com a adesão do presente TERMO DE USO, o USUÁRIO(a) declara tê-lo lido integramente, compreendido em suas minúcias, e, por essa razão manifesta sua plena concordância com referidas alterações declarando que elas não lhe causarão prejuízos diretos ou indiretos, não restringindo-lhe quaisquer de seus direitos.<br /><br />
              Ficam ratificadas todas as demais clausulas e condições do TERMO DE USO ora aditado, que não forem conflitantes a esta alteração, do qual este instrumento passa a fazer parte integrante e indissolúvel para todos os efeitos legais<br /><br />
            </p>
          </InnerSection>
        </Section>
      </Content>
    </main>
  )
}